import React, { Key } from 'react';

import Pagination from '../../../../components/Pagination/Pagination';
import { Button, Container, Row } from 'react-bootstrap';

export interface propTable {
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  combos: any;
  loading: any;
}

const ComboTable: React.FC<propTable> = ({
  totalRows,
  pageCount,
  setPageCount,
  rowsPerPage,
  setRowsPerPage,
  combos,
  loading,
}: propTable) => {

  return (
    <Container className="px-0" fluid>
      <Container className="my-3 py-0 px-0" fluid>
        <Row>
          <div className="col-12 side-list single-product">
            {loading === false ? (
              <>
                {combos?.length > 0
                  ? combos.map((combo: any, index: Key) => {
                    return (
                      <div
                        key={index}
                        className="card card-filter-list w-100 mb-4"
                      >
                        <Row className="mx-0 px-0 h-100">
                          <div className="col-5 col-md-3 px-0 ">
                            <div
                              style={{ height: '100%' }}
                              className="card-img card-img-custom pr-0 p-3"
                            >
                              <div
                                className="bg-product-list"
                                style={{
                                  backgroundImage: `url(${combo.imagesBaseUrl
                                    }${combo.images.length > 0
                                      ? combo.images[0].mediumImg
                                      : ''
                                    })`,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="col-7 col-md-9 text-custom py-3">
                            <div
                              className="h-100"
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div>
                                <h5 className="product-name mb-3">
                                  {combo.comboNameBR}
                                </h5>
                                <div className="d-flex">
                                  <div style={{ paddingRight: '15px' }}>
                                    <p className="small-custom text-muted">
                                      <strong>Código do produto:</strong>
                                      <span> {combo.comboCode}</span>
                                    </p>
                                  </div>
                                </div>
                                {combo.description.length > 0 &&
                                  <div
                                    className="text-product-info mb-0"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        combo.description[0].description[0]
                                          .text,
                                    }}
                                  ></div>
                                }
                              </div>
                              <div>
                                <div>
                                  <Button
                                    variant="outline-primary"
                                    className="btn-default mr-2"
                                    href={`/products/edit-combo?comboCode=${combo.comboCode}`}
                                  >
                                    Editar
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    );
                  })
                  : ''}
              </>
            ) : (
              <>
                {[1, 2, 3, 4, 5].map((tour: any, index: Key) => (
                  <div key={index} className="col-12 col-md-10 side-list w-100">
                    <div
                      className="card card-filter-list w-100 mb-4"
                      style={{ height: '342px' }}
                    >
                      <Row>
                        <div className="col-5 col-md-3 img-mobile">
                          <div
                            className="card-img card-img-custom my-3 ml-3 animated-background"
                            style={{ maxWidth: '480px', height: '249px' }}
                          ></div>
                        </div>
                        <div className="col-7 col-md-7 text-custom pt-3">
                          <h5
                            className="tour-name animated-background"
                            style={{ height: '24px' }}
                          >
                            {' '}
                          </h5>
                          <div className="dates d-flex">
                            <div
                              className="animated-background"
                              style={{
                                height: '20px',
                                width: '35px',
                                marginRight: '5px',
                              }}
                            ></div>
                            <div
                              className="animated-background"
                              style={{
                                height: '20px',
                                width: '35px',
                                marginRight: '5px',
                              }}
                            ></div>
                            <div
                              className="animated-background"
                              style={{
                                height: '20px',
                                width: '35px',
                                marginRight: '5px',
                              }}
                            ></div>
                            <div
                              className="animated-background"
                              style={{
                                height: '20px',
                                width: '35px',
                                marginRight: '5px',
                              }}
                            ></div>
                            <div
                              className="animated-background"
                              style={{
                                height: '20px',
                                width: '35px',
                                marginRight: '5px',
                              }}
                            ></div>
                            <div
                              className="animated-background"
                              style={{
                                height: '20px',
                                width: '35px',
                                marginRight: '5px',
                              }}
                            ></div>
                            <div
                              className="animated-background"
                              style={{
                                height: '20px',
                                width: '35px',
                                marginRight: '5px',
                              }}
                            ></div>
                          </div>
                          <p
                            className="small text-muted animated-background"
                            style={{ height: '20px', marginBottom: '0.5rem' }}
                          ></p>
                          <p
                            className="small text-muted animated-background"
                            style={{ height: '20px', marginBottom: '0.5rem' }}
                          ></p>
                          <p
                            className="small text-muted animated-background"
                            style={{ height: '20px', marginBottom: '0.5rem' }}
                          ></p>
                          <div
                            className="animated-background"
                            style={{ height: '42px' }}
                          ></div>
                        </div>
                        <div
                          className="col-2 col-md-2 m-auto text-center animated-background"
                          style={{ height: '144px', maxWidth: '190px' }}
                        >
                          <span className="small text-muted w-50"></span>
                          <div className="price"></div>
                        </div>
                        <div className="col-12 col-md-"></div>
                      </Row>
                    </div>
                  </div>
                ))}
              </>
            )}
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </Row>
      </Container>
    </Container>
  );
};

export default ComboTable;
