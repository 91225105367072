import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { Controller } from 'react-hook-form';

export interface propsFilter {
  buscar: any;
  setValue: any;
  getValues: any;
  control: any;
}

const ComboFilter: React.FC<propsFilter> = ({
  buscar,
  setValue,
  getValues,
  control,
}: propsFilter) => {
  const { t } = useTranslation();

  const handleFilterSubmit = () => {
    let data: any = getValues();
    buscar(data, true);
  };

  const handleClearSubmit = () => {
    setValue('comboCode', '');
    setValue('comboName', '');
    buscar({}, true);
  };

  return (
    <div
      className="suppliers-filter d-flex justify-content-center bg-white mt-4"
      style={{ textAlign: 'left' }}
    >
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <FontAwesomeIcon
              icon={['fal', 'sliders-h']}
              size="lg"
              className="mr-2"
            />
            <span className="h5 mb-0">Buscar Combos</span>
          </Accordion.Header>
          <Accordion.Body className="pt-0">
            <Container className="p-0" fluid>
              <Form noValidate validated={false}>
                <Row>
                  <div>
                    <Form>
                      <Row className="mb-3">
                        <Form.Group as={Col} md="4">
                          <Form.Label>Nome do Combo</Form.Label>
                          <Controller
                            control={control}
                            name="comboName"
                            defaultValue=""
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                type="text"
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                              />
                            )}
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="4">
                          <Form.Label>Código do Combo</Form.Label>
                          <Controller
                            control={control}
                            name="comboCode"
                            defaultValue=""
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                type="text"
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                              />
                            )}
                          />
                        </Form.Group>
                      </Row>
                      <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                        <Col xs lg="12" className="d-flex justify-content-md-end">
                          <Button
                            variant="outline-primary"
                            className="btn-default mr-2 text-uppercase"
                            onClick={() => {
                              handleClearSubmit();
                            }}
                          >
                            {t('reservations.filter.btnClear')}
                          </Button>
                          <Button
                            className="btn-default text-uppercase"
                            type="button"
                            onClick={() => {
                              handleFilterSubmit();
                            }}
                          >
                            {t('reservations.filter.btnSearch')}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Row>
              </Form>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default ComboFilter;
