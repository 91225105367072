import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import DesktopDefault from '../../templates/DesktopDefault';
import api from '../../services/api';
import { useForm } from 'react-hook-form';

import ComboFilter from './components/ListCombo/ComboFilter';
import ComboTable from './components/ListCombo/ComboTable';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const ListCombo = () => {
  const [combos, setCombos] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);

  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);

  const { control, getValues, setValue } = useForm({});

  function submitCombo(data: any, resetPages: boolean) {
    setLoading(true);
    
    const comboInfos = async () => {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        const res = await api.post(
          `${process.env.REACT_APP_SERVER_URL_API}/Combo/GetComboByFilter`,
          {
            page: resetPages ? 1 : pageCount,
            rowsPerPage: rowsPerPage,
            id: 0,
            comboCode: data.comboCode || '',
            comboNameBR: data.comboName || '',
            comboNameEN: '',
            comboNameES: '',
            buyingAdvance: '',
            idComissionRange: 0,
            location: '',
            address: '',
          },
          config,
        );
        if (res.status !== 400) {
          setCombos(res.data.data.data.rows);
          setTotalRows(res.data.data.data.rowsCount);
          setLoading(false);
        }
        if (resetPages === true) {
          setPageCount(1);
        }
      } catch (error: any) {}
    };
    comboInfos();
  }

  useEffect(() => {
    let data: any = getValues();
    submitCombo(data, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, rowsPerPage]);

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
          <Breadcrumb title={'Listar Combos'} />

          <div className="mt-4 d-flex justify-content-between align-items-center">
            <h2 className="title" style={{ color: '#707070' }}>
              Listar Combos
            </h2>
            <div>
              <Link to="/products/add-combo">
                <Button
                  className="btn-default mr-2 "
                >
                  Adicionar Combo
                </Button>
              </Link>
            </div>
          </div>
          <ComboFilter
            getValues={getValues}
            setValue={setValue}
            control={control}
            buscar={submitCombo}
          />
          <ComboTable
            totalRows={totalRows}
            pageCount={pageCount}
            setPageCount={setPageCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            combos={combos}
            loading={loading}
          />
        </div>
      </DesktopDefault>
    </>
  );
};

export default ListCombo;
